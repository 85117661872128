<template>
  <div class="wrapper-inner">
    <div class="list-container__head clearfix">
      <router-link :to="{name: 'freelancerList'}" class="back"><span class="icon-ico-37-back"></span>Back</router-link>
    </div>
    <div class="list-container__body">
      <div class="company-detail company-detail--freelancer">
        <div class="company-detail__icon">
          <div v-if="freelancerDetails.image_url"
               class="avatar-image"
               id="profileImage"
               :style="{ backgroundImage: 'url(' + getStorageUrl() + freelancerDetails.image_url + ')' }"> </div>
          <div v-else class="avatar-image"><img src="../../assets/images/profilePlaceholder.png" alt=""></div>
        </div>
        <div class="company-detail__info">
          <div class="list-item__name">
            <span>{{freelancerDetails.name}} {{freelancerDetails.lastname}}</span>
            <template v-if="freelancerDetails.location">{{freelancerDetails.location.formatted_address}}</template>
          </div>
          <div v-if="isSkills" class="skills">
            <span class="skills_title">Skills:</span>
            <span v-for="(skill, index) in freelancerDetails.skills">{{skill.name}} {{index !== freelancerDetails.skills.length - 1 ? ', ' : ''}}</span>
          </div>
          <div class="company-info__detail">
            <ul>
              <li v-if="isLawId" class="info-peop-contacts"><b>Lawyer ID number</b>
                <!--TODO fetch lawyer ID's from API-->
                <div class="info-peop-contacts-box">
                  <span v-for="law_id in freelancerDetails.law_id">{{law_id}}</span>
                  <i v-if="isShowMore" class="show-more js_show_more">Show more</i>
                </div>
              </li>
              <li><span class="icon-ico-59-job"></span>{{freelancerDetails.activeOffers}} Active offers</li>
              <li><span class="icon-ico-60-complete"></span>{{freelancerDetails.doneOffers}} job offers successfully completed</li>
              <li @click.prevent="scrollTo">
                <span>Reviews <span class="count">{{Object.keys(freelancerReviews).length > 99 ? '99+' : Object.keys(freelancerReviews).length}}</span></span>
              </li>
              <li v-if="freelancerDetails.rating">
                <div class="list-item-raiting"><span class="icon-ico-53-rate"></span>{{freelancerDetails.rating}}</div>
              </li>
            </ul>
          </div>
          <div class="list-item__list">
            <span>Categories:</span>
            <ul>
              <li v-for="(category, index) in freelancerDetails.categories">
                <span v-if="index === 0" class="icon-ico-43-category"></span>
                {{category.name}}
              </li>
            </ul>
          </div>
        </div>
        <div class="calendar-group">
          <div class="calendar-group-col">
            <div class="mini-calendar">
              <div class="datepicker-date">
                <div id="mini-calendar"></div>
              </div>
            </div>
          </div>
          <div class="calendar-group-col">
            <div class="general-calendar">
              <div id="calendar" class="big-calendar"></div>
            </div>
          </div>
          <div class="calendar-group-col">
            <div class="offers-box">
              <div class="offers-box-over">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list-container-body-head">
      Reviews
      <span class="count">{{Object.keys(freelancerReviews).length > 99 ? '99+' : Object.keys(freelancerReviews).length}}</span>
    </div>
    <div class="reviews-box">
      <div class="reviews-item" v-for="review in freelancerReviews">
        <div class="reviews-author">
          <div class="reviews-author__img">
            <img v-if="review.company.image_url" :src="getStorageUrl() + review.company.image_url" alt="">
            <img v-else src="../../assets/images/profilePlaceholder.png" alt="Placeholder">
          </div>
          <router-link :to="'/companies/' + review.company.id" :key="review.id" class="reviews-author__name">
            {{review.company.name}}
          </router-link>
          <div class="reviews-date">
            {{calendarDateTime(review.created_at)}}
          </div>
        </div>
        <div class="reviews-body">
          {{review.reason && review.reason}}
          <div class="clearfix">
            <div class="review-rating">
              <star-rating :rating="parseFloat(review.rating)" :read-only="true" :star-size="10" :show-rating="false" :max-rating="5"></star-rating>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapActions, mapGetters} from 'vuex'
  import ImageHelper from '../../helpers/ImageHelper'
  import DateHelper from '../../helpers/DateHelper'
  import StarRating from 'vue-star-rating'
  import RolesHelper from '../../helpers/RolesHelper'
  export default {
    data () {
      return {
        events: []
      }
    },
    computed: {
      ...mapGetters(['freelancerDetails', 'freelancerOffers', 'profileInfo', 'freelancerReviews']),
      isShowMore: function () {
        return this.freelancerDetails.law_id && this.freelancerDetails.law_id.length > 3
      },
      isLawId: function () {
        return this.freelancerDetails.law_id && this.freelancerDetails.law_id.length
      },
      isSkills () {
        return this.freelancerDetails.skills && this.freelancerDetails.skills.length
      }
    },
    watch: {
      freelancerOffers (events) {
        events = events.map(event => {
          event.date = event.time ? `${event.date}T${event.time}` : event.date
          event.title = 'Busy'
          return event
        })

        const viewData = this.mainCalendar.fullCalendar('getView')
        this.filteredOffers = events.filter(event => {
          return (event.date >= viewData.start.format('YYYY-MM-DD') && viewData.end.format('YYYY-MM-DD') > event.date)
        })

        this.mainCalendar.fullCalendar('removeEvents')
        this.mainCalendar.fullCalendar('addEventSource', events)
      }
    },
    mixins: [ImageHelper, DateHelper, RolesHelper],
    methods: {
      ...mapActions(['fetchFreelancerReviews', 'fetchFreelancerDetails', 'freelancerDetailsCancel', 'fetchFreelancerOffers', 'offerFromFreelancersCalendar', 'setDateFromCalendar']),
      scrollTo () {
        window.$('body, html').animate({
          scrollTop: window.$('.reviews-box').offset().top
        }, 500)
      }
    },
    components: {
      StarRating
    },
    mounted () {
      this.fetchFreelancerReviews(this.$route.params.id)
      Promise.all([
        this.fetchFreelancerDetails(this.$route.params.id),
        this.fetchFreelancerOffers(this.$route.params.id)
      ]).then(res => {
        this.mainCalendar = window.$('#calendar').fullCalendar({
          header: {
            left: 'agendaDay ,agendaWeek month', // prev,next
            center: 'title',
            right: 'prev,next'
          },
          fixedWeekCount: false,
          navLinks: true,
          editable: false,
          displayEventTime: false,
          eventLimit: true,
          events: this.events,
          dayClick: (date, jsEvent, view, resourceObj) => {
            if (date._d.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
              this.$swal({
                title: 'Can`t create a new offer in the past',
                confirmButtonColor: '#ffffff',
                cancelButtonColor: '#ffffff',
                confirmButtonText: `<button type="submit" class="btn--default prev-hover btn waves-effect waves-light">OK</button>`
              })
            } else if (this.isCompany(this.profileInfo)) {
              this.$swal({
                title: 'Create a new offer?',
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#ffffff',
                cancelButtonColor: '#ffffff',
                confirmButtonText: `<button type="submit" class="btn--default prev-hover btn waves-effect waves-light">OK</button>`,
                cancelButtonText: `<button type="submit" class="btn--default prev-hover btn waves-effect waves-light cancel">Cancel</button>`
              }).then(() => {
                this.setDateFromCalendar(date)
                this.offerFromFreelancersCalendar(this.freelancerDetails)
                this.$router.push('/myCalendar/offerCreation')
              }).catch(() => {})
            }
          }
        })
        let calenH = document.querySelector('.general-calendar .fc-view').clientHeight
        document.querySelector('.offers-box-over').style.maxHeight = calenH - 50 + 'px'
      })
    },
    destroyed () {
      this.freelancerDetailsCancel()
    }
  }
</script>

<style lang="scss">
  .company-detail {
    .fc-toolbar {
      margin-left: -20px !important;

      .fc-right .fc-button-group button {
        display: block !important;
      }
    }

    .offers-box {
      border-top: none;
    }
  }
  .company-detail__icon {
    .avatar-image {
      width: 100% !important;
      height: 100% !important;
      background-position: 50%;
      background-size: cover;
    }
  }
  .company-info__detail ul li.info-peop-contacts .info-peop-contacts-box span:nth-of-type(n + 4) {
    display: none;
  }
  .skills_title {
    font-family: 'Roboto-Medium',sans-serif;
    font-size: 18px;
    color: #272727;
    padding-right: 10px;
  }
  .skills {
    margin-bottom: 15px;
  }

  #calendar {
    .fc-view-container {
      overflow: auto;
    }
    .eventWithWarning {
      background: #ed4e4e !important;
      border-color: #ed4e4e !important;
      color: #272727;
      &:hover {
        color: #fff;
      }
    }
  }

</style>
