<template>
  <div class="general-body general-body--grey clearfix">
    <div class="profile-details clearfix">
      <div class="profile-details__body">
        <div v-if="isFreelancer && Object.keys(freelancerReviews).length">
          <div class="reviews-box">
            <div class="reviews-item" v-for="review in freelancerReviews">
              <div class="reviews-author">
                <div class="reviews-author__img">
                  <img v-if="review.company.image_url" :src="getStorageUrl() + review.company.image_url" alt="">
                  <img v-else src="../../assets/images/profilePlaceholder.png" alt="Placeholder">
                </div>
                <router-link :to="'/companies/' + review.company.id" :key="review.id" class="reviews-author__name">
                  {{review.company.name}}
                </router-link>
                <div class="reviews-date">
                  {{calendarDateTime(review.created_at)}}
                </div>
              </div>
              <div class="reviews-body">
                {{review.reason && review.reason}}
                <div class="clearfix">
                  <div class="re`view-rating">
                    <star-rating :rating="parseFloat(review.rating)" :read-only="true" :star-size="10" :show-rating="false" :max-rating="5"></star-rating>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="Object.keys(companyReviews).length">
          <div class="reviews-box">
            <div class="reviews-item" v-for="review in companyReviews">
              <div class="reviews-author">
                <div class="reviews-author__img">
                  <img v-if="review.freelancer.image_url" :src="getStorageUrl() + review.freelancer.image_url" alt="">
                  <img v-else src="../../assets/images/profilePlaceholder.png" alt="Placeholder">
                </div>
                <router-link :to="'/freelancers/' + review.freelancer.id" :key="review.id" class="reviews-author__name">
                  {{review.freelancer.name + ' ' + review.freelancer.lastname}}
                </router-link>
                <div class="reviews-date">
                  {{calendarDateTime(review.created_at)}}
                </div>
              </div>
              <div class="reviews-body">
                {{review.reason && review.reason}}
                <div class="clearfix">
                  <div class="review-rating">
                    <star-rating :rating="parseFloat(review.rating)" :read-only="true" :star-size="10" :show-rating="false" :max-rating="5"></star-rating>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import StarRating from 'vue-star-rating'
  import ImageHelper from '../../helpers/ImageHelper'
  import DateHelper from '../../helpers/DateHelper'
  export default {
    mixins: [ImageHelper, DateHelper],
    computed: mapGetters(['profileInfo', 'companyReviews', 'freelancerReviews', 'isFreelancer']),
    methods: mapActions(['fetchCompanyReviews', 'fetchFreelancerReviews']),
    components: {
      StarRating
    },
    mounted () {
      this.isFreelancer ? this.fetchFreelancerReviews(this.profileInfo.id) : this.fetchCompanyReviews(this.profileInfo.id)
    }
  }
</script>
