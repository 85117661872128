<template>
  <div class="calendar-body">
    <div class="list-container">
      <div class="list-container__body">
        <div class="list-container-body-head align-center">Edit offer</div>
        <div class="create-offer">
          <form action="#" class="js_validation">
            <div :data-error="errors.first('Name')"
                 :class="[this.errorClass('Name'), 'input-field', 'required-field']">
              <div class="label-field">Offer name</div>
              <div class="label-area">
                <input
                  type="text"
                  name="Name"
                  v-model="offer.name"
                  v-validate="'required'"
                  data-vv-validate-on="submit">
              </div>
            </div>
            <div :data-error="errors.first('Type')" :class="[this.errorClass('Type'), 'input-field', 'required-field']">
              <div class="label-field">Offer type</div>
              <div class="label-area">
                <app-select
                  name="Type"
                  v-model="offer.offer_type_id"
                  v-validate="'required'"
                  data-vv-validate-on="submit">
                  <option value disabled selected></option>
                  <option v-for="type in offerTypes" :value="type.id">{{type.name}}</option>
                </app-select>
              </div>
            </div>
            <div :data-error="errors.first('Description')"
                 :class="[this.errorClass('Description'), 'input-field', 'required-field']">
              <div class="label-field">Offer description</div>
              <div class="label-area">
                    <textarea
                      type="text"
                      class="materialize-textarea"
                      name="Description"
                      v-model="offer.description"
                      v-validate="'required'"
                      data-vv-validate-on="submit"></textarea>
              </div>
            </div>
            <div :data-error="errors.first('Category')" :class="[this.errorClass('Category'), 'input-field', 'required-field']">
              <div class="label-field">Category</div>
              <div class="label-area">
                <app-select
                  name="Category"
                  v-model="offer.category_id"
                  v-validate="'required'"
                  data-vv-validate-on="submit">
                  <option value disabled selected>Select the category</option>
                  <option v-for="category in allCategories" :value="category.id">{{category.name}}</option>
                </app-select>
              </div>
            </div>
            <div :data-error="errors.first('Date')" :class="[this.errorClass('Date'), 'input-field required-field input-field--calendar']">
              <div class="label-field">Date</div>
              <div class="label-area">
                <datepicker v-model="offer.date" name="Date" v-validate="'required'" />
              </div>
            </div>
            <div :data-error="errors.first('Time')" :class="[this.errorClass('Time'), 'input-field required-field']">
              <div class="label-field">Time</div>
              <div class="label-area">
                <input
                  type="time"
                  name="Time"
                  v-model="offer.time"
                  v-validate="'required'"
                  data-vv-validate-on="submit">
              </div>
            </div>
            <div :data-error="errors.first('Location')"
                 :class="[this.errorClass('Location'), 'input-field', 'required-field']">
              <div class="label-field">Location</div>
              <div class="label-area">
                <location-input
                  :getLocationData="getLocationData"
                  v-model="offer.location"
                  :value="location"
                  v-validate="'validate_location'"
                  ref="Location"
                  name="Location"/>
              </div>
            </div>
            <div :data-error="errors.first('Conference link')"
                 :class="[this.errorClass('Conference link'), 'input-field', 'required-field']">
              <div class="label-field">Conference link</div>
              <div class="label-area">
                <input
                  type="text"
                  name="Conference link"
                  v-model="offer.conference_link"
                  v-validate="'required_if:Location,null,'"
                  data-vv-validate-on="submit">
              </div>
            </div>
            <div v-if="!selected_freelancer" :data-error="errors.first('Freelancer Type')"
                 :class="[this.errorClass('Freelancer Type'), 'input-field', 'required-field']">
              <div class="label-field">Type of freelancers</div>
              <div class="label-area">
                <div class="check-box-group">
                  <div class="check-box">
                    <input id="check4" type="radio" value="lawyer" v-model="offer.freelancerType" v-validate="'required'" name="Freelancer Type" class="filled-in">
                    <label for="check4">Lawyers</label>
                  </div>
                  <div class="check-box">
                    <input id="check5" type="radio" value="nonlawyer" v-model="offer.freelancerType" v-validate="'required'" name="Freelancer Type" class="filled-in">
                    <label for="check5">Nonlawyers</label>
                  </div>
                </div>
              </div>
            </div>
            <div :data-error="errors.first('Nonlawyer Type')"
                 :class="[this.errorClass('Nonlawyer Type'), 'input-field', 'required-field']"
                 v-if="offer.freelancerType === 'nonlawyer'">
              <div class="label-field">Type of Nonlawyer</div>
              <div class="label-area">
                <div class="check-box-group">
                  <div class="check-box">
                    <input id="check1" type="radio" value="2" v-model="offer.offer_profile_id" v-validate="'required'" name="Nonlawyer Type" class="filled-in">
                    <label for="check1">Paralegal</label>
                  </div>
                  <div class="check-box">
                    <input id="check2" type="radio" value="3" v-model="offer.offer_profile_id" v-validate="'required'" name="Nonlawyer Type" class="filled-in">
                    <label for="check2">Stenographer</label>
                  </div>
                  <div class="check-box">
                    <input id="check3" type="radio" value="4" v-model="offer.offer_profile_id" v-validate="'required'" name="Nonlawyer Type" class="filled-in">
                    <label for="check3">Expert witness</label>
                  </div>
                </div>
              </div>
            </div>
            <div :data-error="errors.first('Price')"
                 :class="[this.errorClass('Price'), 'input-field', 'required-field']">
              <div class="label-field">Price</div>
              <div class="label-area">
                <input
                  type="text"
                  name="Price"
                  v-model="offer.price"
                  v-validate="{ required: true, regex:/^(\d+\.?\d{0,2}|\.\d{1,2})$/ }"
                >
              </div>
            </div>

            <div v-if="!!offer.offerAttachments.length" class="input-field input-field--area">
              <div class="label-field">Current documents</div>
              <div class="label-area">
                <div class="attachment">
                  <div class="attachment-box">
                    <div class="attachment-head">Attachments</div>
                    <div class="attachment-list">
                      <div v-for="file in offer.offerAttachments" class="attachment-item">
                        <img :src="getIcon(file.url)" alt="">
                        <span class="attachment-name">{{file.name}}</span>
                        <a :href="getStorageUrl() + file.url" target="_blank" class="dowload-file">
                          <span class="icon-ico-47-download"></span>Download
                        </a>
                        <a @click="deleteFile(file)" target="_blank" class="delete-file" title="Delete document">
                          <span class="icon-ico-50-delete"></span>Delete
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="input-field input-field--area">
              <div class="label-field">Add documents</div>
              <div class="label-area">
                <file-dropzone :onFilesChanged="onFilesChanged"/>
              </div>
            </div>

            <spinner v-if="creationProcessing"/>
            <div v-else class="create-offer__button-wrapper">
              <template v-if="selected_freelancer">
                <assigned-freelancer
                  :cssClass="'marginLeft'"
                  :freelancer="selected_freelancer"
                  subtext="Selected Freelancer"
                ></assigned-freelancer>
                <app-button title="To selected Freelancer" :onClick="() => this.selectFreelancer(selected_freelancer)"/>
              </template>

              <template v-if="!selected_freelancer">
                <app-button title="Save" :onClick="updateAnOffer" />
              </template>
              <router-link class="btn modal-close" :to="{name: 'singleJobOffer', props: {offer_id: offer.id}}">cancel</router-link>
            </div>

          </form>
        </div>
      </div>
    </div>
    <div id="modal-choose-friend" class="modal">
      <div class="modal-head">Choose a freelancer</div>
      <div class="modal-content">
        <div class="thank-friend">
          <div class="search-friend">
            <div class="input-field search-field">
              <input type="search" placeholder="Find a freelancer" @keyup="filterFreelancersByName">
            </div>
          </div>
          <div class="friend-list">
            <div class="friend" v-for="freelancer in freelancerList" v-if="freelancer.id !== profileInfo.id">
              <div class="friend-col" @click="showFreelancerInNewTab(freelancer)">
                <div v-if="freelancer.image_url"
                    class="friend-icon"
                    id="freelancer-image"
                    :style="{ backgroundImage: 'url(' + getStorageUrl() + freelancer.image_url + ')' }"></div>
                <div v-else class="friend-icon">
                  <img src="../../assets/images/profilePlaceholder.png" alt="">
                </div>
                <div class="friend-name">{{ freelancer.name }} {{ freelancer.lastname }}</div>
              </div>
              <app-button title="Send" :onClick="() => selectFreelancer(freelancer)"/>
            </div>
          </div>
        </div>
        <div>
          <app-button title="Cancel" cssClass="modal-close"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import ImageHelper from '../../helpers/ImageHelper'
  import Button from '../../components/Button.vue'
  import Select from '../../components/Select.vue'
  import FileDropzone from '../../components/FileDropzone.vue'
  import LocationInput from '../../components/LocationInput.vue'
  import Datepicker from '../../components/Datepicker.vue'
  import Spinner from '../../components/Spinner.vue'
  import AssignedFreelancer from './AssignedFreelancer'
  import DateHelper from '../../helpers/DateHelper'
  import moment from 'moment'
  import {swalOptionsForQuestion} from '@/misc/swallOptions'

  export default {
    data () {
      return {
        offer_id: null,
        location: '',
        offer: {
          name: '',
          description: '',
          offerAttachments: [],
          category_id: null,
          date: null,
          time: null,
          location: '',
          freelancerType: null,
          offer_type_id: null,
          offer_profile_id: null,
          price: '',
          conference_link: '',
        },
        attachments: [],
        attachmentsIdsToDelete: [],
        roleForSearch: null
      }
    },
    computed: {
      ...mapGetters(['allCategories', 'states', 'locations', 'profileInfo', 'dateFromCalendar', 'freelancerList', 'offerTypes', 'selected_freelancer', 'openOfferDetails']),
      creationProcessing () {
        return this.$store.state.jobOffer.isProcessing
      },
      freelancersProcessing () {
        return this.$store.state.freelancer.isProcessing
      },
      offerDateTime () {
        const dateToCreate = moment(this.offer.date).format('YYYY-MM-DD')
        return dateToCreate + ' ' + this.offer.time + ':00'
      }
    },
    watch: {
      profileInfo () {
        if (this.profileInfo.id) {
          this.fetchSingleOffer({profile: this.profileInfo, offer_id: this.$route.params.offer_id})
        }
      },
      openOfferDetails (value) {
        if (value) {
          this.offer_id = value.id
          this.offer = {
            name: value.name,
            description: value.description,
            category_id: value.category.id,
            date: value.date,
            time: value.time.substring(0, 5),
            location: value.location,
            offer_profile_id: value.offerProfile.id,
            freelancerType: value.offerProfile.id === 1 ? 'lawyer' : 'nonlawyer',
            offer_type_id: value.offerType.id,
            price: value.price,
            offerAttachments: value.offerAttachments,
            conference_link: value.conference_link
          }
          this.location = value.location?.formatted_address
        }
      }
    },
    mixins: [ImageHelper, DateHelper],
    methods: {
      ...mapActions(['fetchCategories', 'fetchStates', 'fetchLocations', 'createJobOffer', 'fetchOfferTypes',
        'fetchFreelancerList', 'sendToFreelancer', 'offerFromFreelancersCalendar', 'fetchSingleOffer', 'updateJobOffer']),
      errorClass (field) {
        return {
          error: this.errors.has(field)
        }
      },
      deleteFile (file) {
        this.$swal({
          title: 'Are you sure?',
          text: 'Do you want to delete file: ' + file.name,
          ...swalOptionsForQuestion
        }).then(() => {
          this.attachmentsIdsToDelete.push(file.id)
          this.offer.offerAttachments = [...this.offer.offerAttachments]
            .filter(offerAttachment => offerAttachment.id !== file.id)
        })
      },
      updateAnOffer () {
        this.offer.company_id = this.profileInfo.id
        this.offer.offer_profile_id = this.offer.freelancerType === 'lawyer' ? 1 : this.offer.offer_profile_id
        Promise.all([
          this.$validator.validateAll(),
          //this.$validator.validate('Location', this.offer.location)
        ]).then((result) => {
          if (result.some(valid => !valid)) {
            let err = this.scrollToErrorField()
            if (err) {
              return false
            }
          }

          this.$swal({
            title: 'Please note!',
            text: 'Your Offer will be changed now!',
            ...swalOptionsForQuestion
          }).then(() => {
            this.updateJobOffer({
              offer_id: this.offer_id,
              offerInfo: {
                ...this.offer,
                date: this.offerDateTime
              },
              offerAttachments: this.attachments,
              attachmentsIdsToDelete: this.attachmentsIdsToDelete
            })
          })
        })
      },
      onFilesChanged (files) {
        this.attachments = files.length > 0 ? this.attachments = files : this.attachments = null
      },
      getLocationData (placeResultData) {
        this.offer.location = placeResultData
        this.location = placeResultData.formatted_address
      },
      showFreelancersModal () {
        Promise.all([
          this.$validator.validateAll(),
          //this.$validator.validate('Location', this.offer.location)
        ]).then((result) => {
          let err = false
          if (result.some(valid => !valid)) {
            err = this.scrollToErrorField()
            if (err) {
              return false
            }
          }

          if (!err) {
            this.roleForSearch = this.offer.freelancerType === 'lawyer' ? 2 : 3
            this.fetchFreelancerList({role_id: this.roleForSearch, offer_profile_id: this.offer.offer_profile_id})
            window.$('#modal-choose-friend').modal('open')
          }
        })
      },
      showFreelancerInNewTab (freelancer) {
        window.open('/freelancers/' + freelancer.id)
      },
      filterFreelancersByName (e) {
        this.fetchFreelancerList({search: e.target.value, role_id: this.roleForSearch})
      },
      selectFreelancer (freelancer) {
        this.offer.company_id = this.profileInfo.id
        this.offer.offer_profile_id = this.offer.freelancerType === 'lawyer' ? 1 : this.offer.offer_profile_id
        this.offer.date = this.offerDateTime
        this.createJobOffer({offerInfo: this.offer, offerAttachments: this.attachments, sendToFreelancer: freelancer})

        window.$('#modal-choose-friend').modal('close')
      },
      scrollToErrorField () {
        let errors = window.$('.input-field.error')
        if (errors.length) {
          window.$('html, body').animate({
            scrollTop: errors.offset().top - 80
          }, 300)
          return true
        }
      }
    },
    components: {
      'app-select': Select,
      'app-button': Button,
      'file-dropzone': FileDropzone,
      'location-input': LocationInput,
      'datepicker': Datepicker,
      'spinner': Spinner,
      'assigned-freelancer': AssignedFreelancer
    },
    mounted () {
      window.$('.modal').modal({
        ready () {
          window.$('.modal-overlay').detach().appendTo('.wrapper')
        }
      })

      if (this.profileInfo.id) {
        this.fetchSingleOffer({profile: this.profileInfo, offer_id: this.$route.params.offer_id})
      }

      this.fetchCategories()
      this.fetchOfferTypes()
    },
    created () {
    },
    beforeDestroy () {
      this.offerFromFreelancersCalendar(null)
    }
  }
</script>

<style lang="scss">

  .attachment-item {
    .delete-file {
      cursor: pointer;
    }

    &:hover {
      background:#fff;
      border-color:#e6e6e6;
      span {
        transform:translateY(-10px);
      }
      .dowload-file {
        visibility: visible;
        opacity: 1;
      }
    }

    img {
      height: 48px;
    }

    .attachment-name {
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .create-offer {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button,
    input::-webkit-clear-button {
      -webkit-appearance: none;
    }
  }

  #modal-choose-friend {
    z-index: 1007 !important;

    .friend-list {
      max-height: 300px;
      overflow-y: scroll;

      .friend-col {
        cursor: pointer;
      }
    }

    #freelancer-image {
      background-repeat: no-repeat;
      background-position: 50%;
      border-radius: 50%;
      width: 49px;
      height: 49px;
      background-size: cover;
    }
  }
</style>
