export default class FormErrorHelper {
  static getAll(errors) {
    const errorMessages = [];

    for (const field in errors) {
      if (errors.hasOwnProperty(field)) {
        errorMessages.push(errors[field]);
      }
    }

    return errorMessages.join('<br>');
  }
};
