import axios from 'axios'
import ConfigHelper from '../../helpers/ConfigHelper'
import FormErrorHelper from '@/helpers/FormErrorHelper';
import * as types from './mutationTypes'
import router from '../../router'
import moment from 'moment'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import {PROFILE_INFO_SET_COMPLETED} from "./mutationTypes";

const BASE_URL = ConfigHelper.get('apiUrl')
const PROFILE_URL = BASE_URL + '/profile'
const FREELANCER_PROFILE_URL = (id) => `${BASE_URL}/freelancers/${id}`
const COMPANY_PROFILE_URL = (id) => `${BASE_URL}/companies/${id}`
const PAYMENT_HISTORY_URL = BASE_URL + '/payment_history'
const UPDATE_URL = (profile) => profile.role.id > 1 ? FREELANCER_PROFILE_URL(profile.id) : COMPANY_PROFILE_URL(profile.id)
const NOTIFICATIONS_URL = BASE_URL + '/notifications'
export const IS_PROFILE_COMPLETED_URL = BASE_URL + '/is_profile_complete'
const LOGIN_URL = BASE_URL + '/login'
const REGISTER_URL = BASE_URL + '/register'
const LOGOUT_URL = BASE_URL + '/logout'
const RESET_PASSWORD_URL = BASE_URL + '/reset_password'
const RESET_FORGOTTEN_PASSWORD_URL = BASE_URL + '/reset_forgotten_password'
const CHANGE_PASSWORD_URL = BASE_URL + '/change_password'
const GET_STARTED_URL = BASE_URL + '/get_started'

const echoOptions = {
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_KEY,
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  forceTLS: true
}

const state = {
  profileInfo: {},
  isProcessing: false,
  fetchError: null,
  payments: [],
  socketSubActive: false,
  lastNotification: null,
  notifications: [],
  notificationsUnread: [],
  offerIdForIgnoreUnreadToBell: null,
  notificationsTotal: 0,
  offerIdsForIgnoreActionsList: [],
  isLanding: true,
  authError: {},
  isFreelancer: null,
  isProfileCompleted: false,
  isAuthenticated: !!localStorage.getItem('suitsin_token'),
}

const getters = {
  profileInfo (state) {
    return state.profileInfo
  },
  payments (state) {
    return state.payments
  },
  isFreelancer (state) {
    if (state.profileInfo && state.profileInfo.role) {
      return state.profileInfo.role.id > 1
    }
    return false
  },
  isProcessing (state) {
    return state.isProcessing
  },
  lastNotification (state) {
    return state.lastNotification
  },
  notifications (state) {
    return state.notifications
  },
  notificationsUnread (state) {
    return state.notificationsUnread
  },
  notificationsTotal (state) {
    return state.notificationsTotal
  },
  offerIdsForIgnoreActionsList (state) {
    return state.offerIdsForIgnoreActionsList
  },
  isAuthenticated (state) {
    return state.isAuthenticated
  },
  isProfileCompleted (state) {
    return state.isProfileCompleted
  },
  isLanding (state) {
    return state.isLanding
  },
  authError (state) {
    return state.authError
  }
}
const actions = {
  fetchProfileInfo ({commit, state, dispatch}) {
    commit(types.PROFILE_INFO_FETCH_PROCESSING)
    axios.get(PROFILE_URL).then((res) => {
      const user = res.data.data
      commit(types.PROFILE_INFO_FETCH_SUCCESS, user)
      dispatch('subscribeUserToNotifications')
    }).catch(error => {
      if (error.response && error.response.status === 401) {
        dispatch('logout')
      } else {
        commit(types.PROFILE_INFO_FETCH_ERROR, error)
      }
    })
  },
  subscribeUserToNotifications ({commit, state}) {
    if (!state.socketSubActive) {
      window.Echo.private('user.' + state.profileInfo.auth_id)
        .notification((notification) => {
          commit(types.NOTIFICATIONS_ADD_BOTH, notification)
        })
      commit(types.ACTIVATE_SOCKET_SUBSCRIPTION)
    }
  },
  unsubscribeUserNotifications ({commit, state}) {
    if (state.socketSubActive) {
      window.Echo.leave('user.' + state.profileInfo.auth_id)
      commit(types.DEACTIVATE_SOCKET_SUBSCRIPTION)
    }
  },
  fetchNotifications ({commit, state}, {callBack, page, category}) {
    commit(types.NOTIFICATIONS_FETCH_PROCESSING)
    axios.get(NOTIFICATIONS_URL, {
      params: {
        page: page,
        category: category
      }
    }).then(res => {
      commit(types.NOTIFICATIONS_FETCH_SUCCESS, res.data)
      callBack && callBack()
    }).catch(error => {
      commit(types.NOTIFICATIONS_FETCH_ERROR, error)
    })
  },
  fetchUnreadNotifications ({commit, state}) {
    commit(types.NOTIFICATIONS_FETCH_PROCESSING)
    axios.get(NOTIFICATIONS_URL, {
      params: { unread: true }
    }).then(res => {
      commit(types.NOTIFICATIONS_UNREAD_FETCH_SUCCESS, res.data)
    }).catch(error => {
      commit(types.NOTIFICATIONS_FETCH_ERROR, error)
    })
  },
  removeUnreadNotifications ({commit, state}, offerId) {
    commit(types.NOTIFICATIONS_REMOVE_FROM_UNREAD, offerId)
  },
  updateProfileInfo ({commit, state, dispatch}, data) {
    const dataToUpdate =  data ?? {}
    const profile = {...state.profileInfo, ...dataToUpdate}

    commit(types.PROFILE_INFO_UPDATE_PROCESSING)
    axios.put(UPDATE_URL(profile), profile).then((res) => {
      commit(types.PROFILE_INFO_FETCH_SUCCESS, res.data.data)
      window.Materialize.toast('Profile Info Updated Successfully', 2000)

      if (!state.isProfileCompleted) {
        dispatch('checkProfileCompleted')
      }
    }).catch(error => {
      commit(types.PROFILE_INFO_UPDATE_ERROR, error)
      if (error.response && error.response.status === 422) {
        window.Materialize.toast(FormErrorHelper.getAll(error.response.data.errors), 2000)
      }
    })
  },
  checkProfileCompleted ({commit, state}) {
    axios.get(IS_PROFILE_COMPLETED_URL).then(res => {
      if (res.data.completed) {
        commit(types.PROFILE_INFO_SET_COMPLETED, res.data.completed)
        router.push('/dashboard')
      } else {
        window.Materialize.toast('Please fill out the profile before you start using the application.', 4000)
      }
    }).catch(error => {
      console.log(error)
    })
  },
  updateProfileImage ({commit, state}, image) {
    let profile = state.profileInfo

    commit(types.PROFILE_IMAGE_UPDATE_PROCESSING)
    axios.post(UPDATE_URL(profile) + '/update_image', image).then(res => {
      commit(types.PROFILE_IMAGE_UPDATE_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.PROFILE_IMAGE_UPDATE_ERROR, error)
      window.Materialize.toast('Could not update profile image', 2000)
    })
  },
  updateProfileSignature ({commit, state}, image) {
    let profile = state.profileInfo

    commit(types.PROFILE_SIGNATURE_UPDATE_PROCESSING)
    axios.post(UPDATE_URL(profile) + '/update_signature', image).then(res => {
      commit(types.PROFILE_SIGNATURE_UPDATE_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.PROFILE_SIGNATURE_UPDATE_ERROR, error)
      window.Materialize.toast('Could not update signature', 2000)
    })
  },
  addProfileCategory ({commit, state}, categoryId) {
    const profile = state.profileInfo

    commit(types.PROFILE_ADD_CATEGORY_PROCESSING)
    axios.put(FREELANCER_PROFILE_URL(profile.id) + '/add_category', {category_id: categoryId}).then((res) => {
      commit(types.PROFILE_ADD_CATEGORY_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.PROFILE_ADD_CATEGORY_ERROR, error)
    })
  },
  removeProfileCategory ({commit, state}, categoryId) {
    const profile = state.profileInfo

    commit(types.PROFILE_REMOVE_CATEGORY_PROCESSING)
    axios.put(FREELANCER_PROFILE_URL(profile.id) + '/remove_category', {category_id: categoryId}).then((res) => {
      commit(types.PROFILE_REMOVE_CATEGORY_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.PROFILE_REMOVE_CATEGORY_ERROR, error)
    })
  },
  addPersonalSkill ({commit, state}, name) {
    const profile = state.profileInfo

    commit(types.PROFILE_ADD_PERSONAL_SKILL_PROCESSING)
    axios.put(FREELANCER_PROFILE_URL(profile.id) + '/add_skill', {name}).then((res) => {
      commit(types.PROFILE_ADD_PERSONAL_SKILL_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.PROFILE_ADD_PERSONAL_SKILL_ERROR, error)
    })
  },
  removePersonalSkill ({commit, state}, skill) {
    const profile = state.profileInfo

    commit(types.PROFILE_REMOVE_PERSONAL_SKILL_PROCESSING)
    axios.put(FREELANCER_PROFILE_URL(profile.id) + '/remove_skill', {skill_id: skill.id}).then((res) => {
      commit(types.PROFILE_REMOVE_PERSONAL_SKILL_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.PROFILE_REMOVE_PERSONAL_SKILL_ERROR, error)
    })
  },
  changePassword ({commit, state}, passObj) {
    const payload = {
      email: state.profileInfo.email,
      new_password: passObj.newPassword,
      old_password: passObj.oldPassword
    }
    commit(types.PROFILE_CHANGE_PASSWORD_PROCESSING)
    axios.post(CHANGE_PASSWORD_URL, payload).then(response => {
      commit(types.PROFILE_CHANGE_PASSWORD_SUCCESS)
      window.Materialize.toast(response.data.message, 2000)
    }).catch(error => {
      commit(types.PROFILE_CHANGE_PASSWORD_ERROR, error)
      if (error.response && error.response.status === 422) {
        window.Materialize.toast(FormErrorHelper.getAll(error.response.data.errors), 2000)
      }
    })
  },
  fetchPaymentHistory ({commit, state}) {
    commit(types.PROFILE_PAYMENT_HISTORY_FETCH_PROCESSING)
    axios.get(PAYMENT_HISTORY_URL).then((res) => {
      commit(types.PROFILE_PAYMENT_HISTORY_FETCH_SUCCESS, res.data.data)
    }).catch(error => {
      commit(types.PROFILE_PAYMENT_HISTORY_FETCH_SUCCESS, error)
    })
  },
  signIn ({commit, dispatch}, creds) {
    commit(types.USER_AUTH_PROCESSING)
    window.genFunc.turnOnPreloader(true)
    axios
      .post(LOGIN_URL, creds)
      .then(res => {
        localStorage.setItem('suitsin_token', `Bearer ${res.data.access_token}`)
        axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.access_token}`
        try {
          // setup echo
          window.Echo = new Echo({
            ...echoOptions,
            client: new Pusher(echoOptions.key, {
              ...echoOptions,
              channelAuthorization: {
                endpoint: process.env.VUE_APP_API_URL + '/broadcasting/auth',
                headers: {
                  authorization: localStorage.getItem('suitsin_token')
                }
              }
            })
          })
        } catch (e) {
          console.log(e)
        }
        commit(types.USER_SIGN_IN_SUCCESS)

        // Set is profile completed
        const profile_completed = res.data.profile_completed
        commit(types.PROFILE_INFO_SET_COMPLETED, profile_completed)

        // Set profile data
        const profile = res.data.profile
        commit(types.PROFILE_INFO_FETCH_SUCCESS, profile)

        dispatch('subscribeUserToNotifications')

        profile_completed ? router.push('/dashboard') : router.push('/profile')
      })
      .catch((error) => {
        window.genFunc.turnOnPreloader(false)
        if (error.response) {
          if (error.response.data.message.includes('mail')) {
            commit(types.USER_SIGN_IN_ERROR, {Email: error.response.data.message})
          } else {
            commit(types.USER_SIGN_IN_ERROR, {Password: error.response.data.message})
          }
        }
      })
  },

  register ({commit, state}, creds) {
    commit(types.USER_AUTH_PROCESSING)
    axios.post(REGISTER_URL, creds).then((res) => {
      commit(types.USER_REGISTER_SUCCESS)
      router.push('/registrationSuccess')
    }).catch((error) => {
      if (error.response && error.response.status === 422) {
        window.Materialize.toast(FormErrorHelper.getAll(error.response.data.errors), 5000)
      } else {
        window.Materialize.toast(error.response.data.message, 5000)
      }
      // switch (error.response.status) {
      //   case 422:
      //     commit(types.USER_SIGN_IN_ERROR, {Email: 'The email has already been taken'})
      //     break
      //   default:
      //     commit(types.USER_SIGN_IN_ERROR, error.response.data)
      // }
    })
  },

  logout ({commit, dispatch}) {
    axios.post(LOGOUT_URL).then(res => {
      dispatch('unsubscribeUserNotifications')
      localStorage.removeItem('suitsin_token')
      commit(types.USER_LOGOUT)
      router.push('/signIn')
    }).catch(error => {
      console.log(error.response)
    })
  },

  resetPassword ({commit, state}, email) {
    axios.post(RESET_PASSWORD_URL, {email}).then(res => {
      //commit(types.RESET_PASSWORD)
      router.push('/signIn')
      window.Materialize.toast('A link to reset your password has been sent to you. Please, check the mail.', 4000)
    }).catch(error => {
      if (error.response && error.response.status === 422) {
        window.Materialize.toast(FormErrorHelper.getAll(error.response.data.errors), 2000)
      } else {
        window.Materialize.toast(error.response.data.message, 5000)
      }
    })
  },

  changeForgottenPassword ({commit, state}, data) {
    axios.post(RESET_FORGOTTEN_PASSWORD_URL, data).then(res => {
      commit(types.RESET_PASSWORD)
      router.push('/signIn')
      window.Materialize.toast('You have successfully changed your password.', 4000)
    }).catch(error => {
      if (error.response && error.response.status === 422) {
        router.push('/forgotPassword')

        window.Materialize.toast('Tokens mismatch, please try again.', 4000)
      }
    })
  },

  delete ({commit, dispatch}) {
    axios.delete(PROFILE_URL, {
      data: {confirm: true}
    }).then(res => {
      if (res.data.success) {
        window.Materialize.toast(res.data.message, 4000)
        dispatch('unsubscribeUserNotifications')
        localStorage.removeItem('suitsin_token')
        commit(types.USER_LOGOUT)
        router.push('/signIn')
      } else {
        window.Materialize.toast(res.data.message, 5000)
      }
    }).catch(error => {
      if (error.response && error.response.status === 422) {
        window.Materialize.toast(FormErrorHelper.getAll(error.response.data.errors), 5000)
      } else {
        window.Materialize.toast(error.response.data.message, 5000)
      }
    })
  },

  cancelErrors ({commit, state}) {
    commit(types.CANCEL_ERRORS)
  },
  setIsLanding ({commit, state}, bool) {
    commit(types.IS_LANDING, bool)
  },
  getStarted ({commit, state}, data) {
    axios.post(GET_STARTED_URL, { name: data.name, email: data.email, message: data.message }).then(res => {
      window.Materialize.toast('New request added successfully', 4000)
    }).catch(error => {
      window.Materialize.toast(error, 4000)
    })
  }
}

const mutations = {
  [types.PROFILE_INFO_FETCH_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.PROFILE_INFO_FETCH_SUCCESS] (state, data) {
    state.isProcessing = false
    state.profileInfo = data
    return state
  },
  [types.PROFILE_INFO_SET_COMPLETED] (state, data) {
    state.isProfileCompleted = data
    return state
  },
  [types.PROFILE_INFO_FETCH_ERROR] (state, error) {
    state.isProcessing = false
    state.fetchError = error
    return state
  },
  [types.PROFILE_CALENDAR_SYNCED_SUCCESS] (state) {
    return state.profileInfo.google_calendar_synced = true
  },
  [types.ACTIVATE_SOCKET_SUBSCRIPTION] (state) {
    return state.socketSubActive = true
  },
  [types.DEACTIVATE_SOCKET_SUBSCRIPTION] (state) {
    return state.socketSubActive = false
  },
  [types.NOTIFICATIONS_FETCH_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.NOTIFICATIONS_FETCH_SUCCESS] (state, data) {
    state.isProcessing = false
    state.notifications = data.data
    state.notificationsTotal = data.meta.total
    state.offerIdsForIgnoreActionsList = []
    return state
  },
  [types.NOTIFICATIONS_UNREAD_FETCH_SUCCESS] (state, data) {
    state.isProcessing = false
    state.notificationsUnread = data.data
    return state
  },
  [types.NOTIFICATIONS_FETCH_ERROR] (state, error) {
    state.isProcessing = false
    state.fetchError = error
    return state
  },
  [types.NOTIFICATIONS_SET_LAST] (state, data) {
    state.lastNotification = data
    return state
  },
  [types.NOTIFICATIONS_ADD_BOTH] (state, data) {
    const offerId = data.offer_id ? data.offer_id : null

    if (data.hasOwnProperty('read_all') && offerId) {
      state.notificationsUnread = [...state.notificationsUnread].filter(n => n.offer_id !== offerId)
    }

    const notification = {
      id: Date.now(),
      offer_id: offerId,
      created_at: new Date(),
      read_at: null,
      category: data.category,
      subcategory: data.category,
      data: data
    }

    state.lastNotification = notification

    if (state.offerIdForIgnoreUnreadToBell !== offerId) {
      state.notificationsUnread = [
        notification,
        ...state.notificationsUnread
      ]
    }

    return state
  },
  [types.NOTIFICATIONS_REMOVE_FROM_UNREAD] (state, offerId) {
    state.notificationsUnread = [...state.notificationsUnread].filter(n => n.offer_id !== offerId)
    return state
  },
  [types.NOTIFICATIONS_ADD_IGNORE_OFFERS] (state, offerId) {
    state.offerIdsForIgnoreActionsList = [...state.offerIdsForIgnoreActionsList, offerId]
    return state
  },
  [types.NOTIFICATIONS_REMOVE_IGNORE_OFFERS] (state, offerId) {
    state.offerIdsForIgnoreActionsList = [...state.offerIdsForIgnoreActionsList].filter(id => id !== offerId)
    return state
  },
  [types.NOTIFICATIONS_ADD_IGNORE_TO_BELL] (state, offerId) {
    state.offerIdForIgnoreUnreadToBell = offerId
    return state
  },
  [types.NOTIFICATIONS_REMOVE_IGNORE_TO_BELL] (state) {
    state.offerIdForIgnoreUnreadToBell = null
    return state
  },
  [types.PROFILE_INFO_UPDATE_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.PROFILE_INFO_UPDATE_SUCCESS] (state, data) {
    state.isProcessing = false
    state.profileInfo = data
    return state
  },
  [types.PROFILE_INFO_UPDATE_ERROR] (state, error) {
    state.isProcessing = false
    state.fetchError = error
    return state
  },
  [types.PROFILE_IMAGE_UPDATE_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.PROFILE_IMAGE_UPDATE_SUCCESS] (state, data) {
    state.isProcessing = false
    state.profileInfo.image_url = data.image_url
    return state
  },
  [types.PROFILE_IMAGE_UPDATE_ERROR] (state, error) {
    state.isProcessing = false
    state.fetchError = error
    return state
  },
  [types.PROFILE_SIGNATURE_UPDATE_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.PROFILE_SIGNATURE_UPDATE_SUCCESS] (state, data) {
    state.isProcessing = false
    state.profileInfo.signature_url = data.signature_url
    return state
  },
  [types.PROFILE_SIGNATURE_UPDATE_ERROR] (state, error) {
    state.isProcessing = false
    state.fetchError = error
    return state
  },
  [types.PROFILE_ADD_CATEGORY_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.PROFILE_ADD_CATEGORY_SUCCESS] (state, data) {
    state.isProcessing = false
    // state.profileInfo = data
    return state
  },
  [types.PROFILE_ADD_CATEGORY_ERROR] (state, error) {
    state.isProcessing = false
    state.fetchError = error
    return state
  },
  [types.PROFILE_REMOVE_CATEGORY_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.PROFILE_REMOVE_CATEGORY_SUCCESS] (state, data) {
    state.isProcessing = false
    // state.profileInfo = data
    return state
  },
  [types.PROFILE_REMOVE_CATEGORY_ERROR] (state, error) {
    state.isProcessing = false
    state.fetchError = error
    return state
  },
  [types.PROFILE_ADD_PERSONAL_SKILL_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.PROFILE_ADD_PERSONAL_SKILL_SUCCESS] (state, data) {
    state.isProcessing = false
    state.profileInfo = data
    return state
  },
  [types.PROFILE_ADD_PERSONAL_SKILL_ERROR] (state, error) {
    state.isProcessing = false
    state.fetchError = error
    return state
  },
  [types.PROFILE_REMOVE_PERSONAL_SKILL_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.PROFILE_REMOVE_PERSONAL_SKILL_SUCCESS] (state, data) {
    state.isProcessing = false
    state.profileInfo = data
    return state
  },
  [types.PROFILE_REMOVE_PERSONAL_SKILL_ERROR] (state, error) {
    state.isProcessing = false
    state.fetchError = error
    return state
  },
  [types.PROFILE_CHANGE_PASSWORD_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.PROFILE_CHANGE_PASSWORD_SUCCESS] (state, data) {
    state.isProcessing = false
    //state.profileInfo = data
    return state
  },
  [types.PROFILE_CHANGE_PASSWORD_ERROR] (state, error) {
    state.isProcessing = false
    state.fetchError = error
    return state
  },
  [types.PROFILE_PAYMENT_HISTORY_FETCH_PROCESSING] (state) {
    return state.isProcessing = true
  },
  [types.PROFILE_PAYMENT_HISTORY_FETCH_SUCCESS] (state, data) {
    state.isProcessing = false
    state.payments = data
    return state
  },
  [types.PROFILE_PAYMENT_HISTORY_FETCH_ERROR] (state, error) {
    state.isProcessing = false
    state.fetchError = error
    return state
  },
  [types.USER_AUTH_PROCESSING] (state) {
    state.isProcessing = true
    return state
  },
  [types.USER_SIGN_IN_SUCCESS] (state) {
    state.isAuthenticated = true
    return state
  },
  [types.USER_SIGN_IN_ERROR] (state, error) {
    state.authError = error
    return state
  },
  [types.USER_REGISTER_SUCCESS] (state) {
    return state
  },
  [types.USER_REGISTER_ERROR] (state, error) {
    state.authError = error
    return state
  },
  [types.USER_LOGOUT] (state) {
    state.isAuthenticated = false
    state.isProfileCompleted = false
    state.profileInfo = {}
    state.lastNotification = null
    state.notifications = []
    state.notificationsUnread = []

    return state
  },
  [types.CANCEL_ERRORS] (state) {
    state.authError = {}
    return state
  },
  [types.IS_LANDING] (state, value) {
    state.isLanding = value
    return state
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
